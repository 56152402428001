var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.playersArePresent)?_c('div',{staticClass:"vtb-item"},[(_vm.bracketNode.status !== 'technical')?[(_vm.bracketNode.player1.empty && _vm.bracketNode.player2.empty)?_c('div',{class:_vm.getBracketNodeClass(_vm.bracketNode),attrs:{"data-match":_vm.bracketNode.match_id}},[_c('game-players',{attrs:{"bracket-node":_vm.bracketNode},scopedSlots:_vm._u([{key:"player",fn:function(ref){
var player = ref.player;
return [(player.empty)?_c('div',{staticClass:"logo"}):_vm._e(),(player.empty)?_c('div',{staticClass:"name"}):_vm._e()]}}],null,false,4173077087)}),_c('div',{staticClass:"player-road",class:_vm.position},[_c('div',{staticClass:"after"})])],1):_c('div',{class:_vm.getBracketNodeClass(_vm.bracketNode),attrs:{"data-match":_vm.bracketNode.match_id}},[_c('game-players',{attrs:{"bracket-node":_vm.bracketNode,"highlighted-player-id":_vm.highlightedPlayerId},scopedSlots:_vm._u([{key:"player",fn:function(ref){
var player = ref.player;
return [[(player.empty)?_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/img/user_logo.png"),"alt":"#"}})]):_c('router-link',{staticClass:"logo",attrs:{"to":{name: _vm.TOURNAMENT.attributes.participant_type === 'USER' ? 'another-profile' : 'team.name', params: {id: player.id}},"target":"_blank"}},[_c('img',{attrs:{"src":player.logo,"alt":"#"}})])],[(player.empty)?_c('div',{staticClass:"name"},[_vm._t("player",null,{"player":player})],2):_c('router-link',{staticClass:"name",attrs:{"to":{name: _vm.TOURNAMENT.attributes.participant_type === 'USER' ? 'another-profile' : 'team.name', params: {id: player.id}},"target":"_blank"}},[_vm._t("player",null,{"player":player})],2)],(_vm.bracketNode.status === 'finished')?_c('div',{staticClass:"status",class:{winner: player.winner}},[_vm._v(_vm._s(player.victoryOnPoints))]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"player-road",class:_vm.position},[_c('div',{staticClass:"after"})]),_vm._t("third-place")],2)]:_c('div',{staticStyle:{"height":"88px"}}),(_vm.bracketNode.games[0] || _vm.bracketNode.games[1])?_c('div',{staticClass:"vtb-item-children"},[(_vm.bracketNode.games[0])?_c('div',{staticClass:"vtb-item-child"},[_c('bracket-node',{attrs:{"position":"top","bracket-node":_vm.bracketNode.games[0],"highlighted-player-id":_vm.highlightedPlayerId},scopedSlots:_vm._u([{key:"player",fn:function(ref){
var player = ref.player;
return [_vm._t("player",null,{"player":player})]}}],null,true)})],1):_vm._e(),(_vm.bracketNode.games[1])?_c('div',{staticClass:"vtb-item-child"},[_c('bracket-node',{attrs:{"position":"bottom","bracket-node":_vm.bracketNode.games[1],"highlighted-player-id":_vm.highlightedPlayerId},scopedSlots:_vm._u([{key:"player",fn:function(ref){
var player = ref.player;
return [_vm._t("player",null,{"player":player})]}}],null,true)})],1):_vm._e()]):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }