<template>
  <div class="ph-card" :class="{grand: this.grandFinal, big_final: this.getGrandFinalClass}">
    <div class="s1">{{getRound}}</div>
    <div class="s2"><!--чт, 23 Сен 2020, 03:00 (+07)--></div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "play-off-header",
  props: {
    round: {
      type: Number,
      default: 0
    },
    grandFinal: {
      type: Boolean,
      default: false
    },
    grandFinalRoundsCount: {
      type: Number,
      default: 2
    }
  },
  computed: {
    ...mapGetters([
      'TOURNAMENT'
    ]),
    getRound() {
      if (this.grandFinal) {
        return 'Гранд финал'
      }

      switch (this.round) {
        case 2:
          return 'Четвертьфинал'
        case 1:
          return 'Полуфинал';
        case 0:
          return 'Финал';
        default:
          return `1/${Math.pow(2, this.round)}`;
      }
    },
    getGrandFinalClass() {
      return this.grandFinalRoundsCount > 1;
    }
  },
}
</script>

<style scoped>

</style>
