<template>
  <div class="vtb-item" v-if="playersArePresent">
    <!-- Если матч не технический, то показываем это -->
    <template v-if="bracketNode.status !== 'technical'">
      <!-- Если нет ни одного партисипанта в матче, то показываем квадраты просто -->
      <div v-if="bracketNode.player1.empty && bracketNode.player2.empty"
           :data-match="bracketNode.match_id"
           :class="getBracketNodeClass(bracketNode)"
      >
        <game-players :bracket-node="bracketNode">
          <template #player="{ player }">
            <div v-if="player.empty" class="logo"></div>
            <div v-if="player.empty" class="name"></div>
          </template>
        </game-players>
        <div class="player-road" :class="position">
          <div class="after"></div>
        </div>
      </div>

      <div v-else
           :class="getBracketNodeClass(bracketNode)"
           :data-match="bracketNode.match_id"
      >
        <game-players
            :bracket-node="bracketNode"
            :highlighted-player-id="highlightedPlayerId"
        >
          <template #player="{ player }">
            <template>
              <!-- Если флаг емпти - тру, то мы показывает дефолтную фотку -->
              <div
                  v-if="player.empty"
                  class="logo"
              >
                <img src="@/img/user_logo.png" alt="#">
              </div>
              <!-- Если нет, то фотку партисипанта со ссылкой на профиль или тиму -->
              <router-link
                  v-else
                  :to="{name: TOURNAMENT.attributes.participant_type === 'USER' ? 'another-profile' : 'team.name', params: {id: player.id}}"
                  class="logo"
                  target="_blank"
              >
                <img :src="player.logo" alt="#">
              </router-link>
            </template>
            <template>
              <!-- Если флаг емпти - тру, то мы показывает TBA(to be announced) -->
              <div
                  v-if="player.empty"
                  class="name"
              >
                <slot name="player" :player="player"/>
              </div>
              <!-- Если нет, то нейм партисипанта, со ссылкой на профиль или тиму -->
              <router-link
                  v-else
                  :to="{name: TOURNAMENT.attributes.participant_type === 'USER' ? 'another-profile' : 'team.name', params: {id: player.id}}"
                  class="name"
                  target="_blank"
              >
                <slot name="player" :player="player"/>
              </router-link>
            </template>
            <!-- Если матч не завершен, то мы не показываем буковки В/П -->
            <div
                v-if="bracketNode.status === 'finished'"
                class="status"
                :class="{winner: player.winner}"
            >{{ player.victoryOnPoints }}</div>
<!--            Мне нужно это место-->
          </template>
        </game-players>
        <div class="player-road" :class="position">
          <div class="after"></div>
        </div>

        <slot name="third-place" />
      </div>
    </template>
    <!-- Если матч технический, то это показываем -->
    <div v-else style="height:88px"></div>

    <!-- Зачем тут это так и не разобрался, но без него все ломается пздц -->
    <div v-if="bracketNode.games[0] || bracketNode.games[1]" class="vtb-item-children">
      <div class="vtb-item-child" v-if="bracketNode.games[0]">
        <bracket-node
            position="top"
            :bracket-node="bracketNode.games[0]"
            :highlighted-player-id="highlightedPlayerId"
        >
          <template #player="{ player }">
            <slot name="player" :player="player"/>
          </template>
        </bracket-node>
      </div>
      <div class="vtb-item-child" v-if="bracketNode.games[1]">
        <bracket-node
            position="bottom"
            :bracket-node="bracketNode.games[1]"
            :highlighted-player-id="highlightedPlayerId"
        >
          <template #player="{ player }">
            <slot name="player" :player="player"/>
          </template>
        </bracket-node>
      </div>
    </div>
  </div>
</template>

<script>
import GamePlayers from "./GamePlayers";
import {mapGetters} from "vuex";

export default {
  name: "bracket-node",
  components: {GamePlayers},
  props: ["bracketNode", "highlightedPlayerId", "position"],
  computed: {
    ...mapGetters([
      'TOURNAMENT'
    ]),
    playersArePresent() {
      return this.bracketNode.player1 && this.bracketNode.player1;
    },
  },
  methods: {
    getBracketNodeClass(bracketNode) {

      if (bracketNode.games[0] || bracketNode.games[1]) {
        return 'vtb-item-parent';
      }

      if (bracketNode.hasParent) {
        return 'vtb-item-child';
      }

      return '';
    },

    getPlayerClass(player) {
      if (player.winner === null || player.winner === undefined) {
        return "";
      }

      return player.winner ? "winner" : "defeated";
    },

    /**
     * Добавляем ноде, у которой только 1 дочерний матч класс, что бы линия была ровной, так как они будут стоять на одной высоте
     * */
    getSingleParentNodeLineClass() {

      if (this.bracketNode.games.length === 1) {
        let el = document.querySelector(`[data-match='${this.bracketNode.games[0].match_id}']`);
        el?.classList.add('straight-line');
      }

    }
  },
  mounted() {
    this.getSingleParentNodeLineClass();
  }
};
</script>

<style lang="scss">


</style>
