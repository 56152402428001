<template>
  <div class="vtb-wrapper" v-if="recursiveBracket">
    <bracket-node
        :position="getPositionForPlayerRoad"
        :bracket-node="recursiveBracket"
        :highlighted-player-id="highlightedPlayerId"
    >
      <template #player="{ player }">
        <slot name="player" :player="player"/>
      </template>

      <template v-slot:third-place>
        <template v-if="thirdPlace && thirdPlace.length">
          <bracket-node
              :bracket-node="thirdPlaceBracket"
              :highlighted-player-id="highlightedPlayerId"
              class="third-place"
          >
            <template #player="{ player }">
              <slot name="player" :player="player"/>
            </template>
          </bracket-node>
        </template>
      </template>

    </bracket-node>
  </div>
</template>

<script>
import BracketNode from "./components/BracketNode";
import recursiveBracket from "./components/recursiveBracket";

export default {
  name: "bracket",
  components: {
    "bracket-node": BracketNode,
  },
  props: ["rounds", "flatTree", "thirdPlace"],
  data() {
    return {
      highlightedPlayerId: null,
    };
  },
  computed: {
    recursiveBracket() {
      let result;
      if (this.flatTree) {
        result = recursiveBracket.transformFlatTree(this.flatTree);
      } else {
        result = recursiveBracket.transform(this.rounds);
      }

      return result;
    },
    thirdPlaceBracket() {
      let result = [];
      if (this.thirdPlace.length) {
        result = recursiveBracket.transform(this.thirdPlace)
        return result
      }
      return result
    },
    getPositionForPlayerRoad() {
      if (this.flatTree) {
        return 'bottom';
      }
      return 'top';
    }
  },
  methods: {
    getWidthForLastLine() {
      let upperGrid = document.querySelector('.upper-grid'),
          lowerGrid = document.querySelector('.lower-grid'),

          upperWidth = upperGrid.scrollWidth - upperGrid.querySelector('.vtb-item').scrollWidth,
          lowerWidth = lowerGrid.scrollWidth - lowerGrid.querySelector('.vtb-item').scrollWidth,

          upperRoad = document.querySelector('.upper-grid .vtb-item .vtb-item-parent .player-road'),
          lowerRoad = document.querySelector('.lower-grid .vtb-item .vtb-item-parent .player-road'),
          upperLine = upperRoad.querySelector('.after'),
          lowerLine = lowerRoad.querySelector('.after');


      upperLine.style.width = upperWidth + 20 + 'px';
      upperLine.style.right = 'auto';
      upperLine.style.left = '20px';

      lowerLine.style.width = lowerWidth + 20 + 'px';
      lowerLine.style.right = 'auto';
      lowerLine.style.left = '20px';


      let wrapper = document.querySelector('.de-grids__wrapper');
      let wrapperHeight = wrapper.scrollHeight;

      upperRoad.style.height = (wrapperHeight - upperGrid.scrollHeight) / 2 + 5 + 'px';
      lowerRoad.style.height = (wrapperHeight - lowerGrid.scrollHeight) / 2 - 49 + 'px';
    }
  },
  mounted() {
    if (this.flatTree) {
      this.getWidthForLastLine();
    }
  }
};
</script>

<style>
.vtb-wrapper {
  display: flex;
}
</style>
