<template>
  <div class="vtb-item-players">
    <div v-if="bracketNode.player1.empty || bracketNode.player2.empty">
      <div
          :data-player-id="bracketNode.player1.id"
          :class="['vtb-player', 'vtb-player1', getPlayerClass(bracketNode.player1)]"
      >
        <slot :player="bracketNode.player1" name="player"/>
      </div>

      <div
          :data-player-id="bracketNode.player2.id"
          :class="['vtb-player', 'vtb-player2', getPlayerClass(bracketNode.player2)]"
      >
        <slot :player="bracketNode.player2" name="player"/>
      </div>
    </div>
    <div v-else>
      <router-link
          :to="{name: 'SingleMatch', params: {id: bracketNode.match_id}}"
          target="_blank"
          :data-player-id="bracketNode.player1.id"
          :class="['vtb-player', 'vtb-player1', getPlayerClass(bracketNode.player1)]"
          @mouseover.native="highlightPlayer(bracketNode.player1.id)"
          @mouseleave.native="unhighlightPlayer"
      >
        <slot :player="bracketNode.player1" name="player"/>
      </router-link>

      <router-link
          :to="{name: 'SingleMatch', params: {id: bracketNode.match_id}}"
          target="_blank"
          :data-player-id="bracketNode.player2.id"
          :class="['vtb-player', 'vtb-player2', getPlayerClass(bracketNode.player2)]"
          @mouseover.native="highlightPlayer(bracketNode.player2.id)"
          @mouseleave.native="unhighlightPlayer"
      >
        <slot :player="bracketNode.player2" name="player"/>
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "game-players",
  props: ["bracketNode", "highlightedPlayerId"],
  computed: {
    ...mapGetters([
      'TOURNAMENT'
    ]),
    matchProperties() {
      return Object.assign({}, this.bracketNode, {games: undefined, hasParent: undefined});
    }
  },
  methods: {
    getPlayerClass(player) {
      if (player.winner === null || player.winner === undefined) {
        return "";
      }

      return player.winner ? "winner" : "defeated";
    },
    highlightPlayer(playerId) {

      const event = new CustomEvent('onSelectedPlayer', {'detail': {playerId}});
      window.dispatchEvent(event);

      let elems = [...document.querySelectorAll(`[data-player-id='${playerId}']`)].filter(el => el.classList.contains('winner'))
      if (elems.length) {
        elems.map(el => {
          let parent = el.parentNode.parentNode
          let line = parent.nextSibling
          line.classList.add('highlight')
        })
      }

    },
    unhighlightPlayer() {

      const event = new Event('onDeselectedPlayer');
      window.dispatchEvent(event);

      [...document.querySelectorAll('.player-road')].map(el => el.classList.remove('highlight'));

    }
  },
};
</script>
